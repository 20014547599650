import 'css/src/components/public-pages/Page404';

import React, {} from 'react';

import { Urls } from 'js/src/client';


const Page404 = React.memo(function Page404() {
    return (
        <div className="page-404-container mt-l5-0 overflow-x-hidden">
            {/* Section 1 */}
            <div className="p-l5-48">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        {/* section 1.1 */}
                        <div className="col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-start my-l5-48 ms-l5-md-48 ps-l5-md-48">
                                <h3 className="w-50">
                                    Sorry, we can’t find this page.
                                </h3>
                                <h5 className="mt-l5-8 pt-l5-16 px-l5-0 w-75">
                                    <a href={Urls.HOME} className="text-primary text-underline">Return home</a> or&nbsp;
                                    <a href="https://help.lumen5.com" className="text-primary text-underline">get some help</a>.
                                </h5>
                            </div>
                        </div>
                        {/* section 1.2 */}
                        <div className="col-md-6 col-sm-12">
                            <div className="d-flex container justify-content-sm-start justify-content-md-center align-items-center mt-l5-48 pt-l5-48">
                                <img className="w-100 icon-placeholder banner-img"
                                    src="https://storage.googleapis.com/lumen5-site-images/website-assets/404-image.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Page404;
